import React, { useEffect } from "react";
import Header from "../Helpers/Header";
import "./Howtoruninapple.css";
import firebase from "firebase";
import db, { auth } from "../Helpers/Firebase";
import Apple1 from "../Assets/Apple/apple1.png";
import Apple2 from "../Assets/Apple/apple2.png";
import Apple3 from "../Assets/Apple/apple3.png";
// import Apple4 from "../Assets/Apple/apple4.png";

function Howtoruninapple() {
  useEffect(() => {}, []);
  return (
    <div className="howtoruninapple">
      <Header />
      <div>
        <h4>
          If you download any of the software in Mac OS and get the following
          issue:
        </h4>
        <img src={Apple1} alt="" />
        <h4>Then follow this steps:</h4>
        <h4>
          1. Go to apple icon at top left corner of your desktop and select
          System Preference
        </h4>
        <img src={Apple2} alt="" />
        <h4>Go to Security and Privacy</h4>
        <img src={Apple3} alt="" />
        <h4>Scroll down to "Allow apps downloaded from" section</h4>
        <h4>
          If you using newer version of OS then you will find it in General Tab
          or else just scroll down the list of the apps and below that you will
          find this section
        </h4>

        {/* <img src={Apple4} /> */}
        <h4>
          You will find the name of the software you are trying to install and
          will have OPEN ANYWAY button near it. Click on that button and retry
          opening the software.
        </h4>
      </div>
    </div>
  );
}

export default Howtoruninapple;
