import logo from "./logo.svg";
import "./App.css";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Appnested from "./Appnested";
import SnackbarProvider from "react-simple-snackbar";

function App() {
  return (
    <div className="App">
      <Router>
        <SnackbarProvider>
          <Appnested />
        </SnackbarProvider>
      </Router>
    </div>
  );
}

export default App;
