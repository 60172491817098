import React, { useState, useEffect, useRef } from "react";
import "./FinancialPlanningTool.css";
import User from "../Assets/user.png";
import ShowGraph from "./ShowGraph";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useParams } from "react-router-dom";
import db from "../Helpers/Firebase";
import Webcam from "react-webcam";
import ReactToPdf from "react-to-pdf";
import Header from "../Helpers/Header";
import help from "../Helpers/Help_methods";
import FinacialPlanningQA from "./FinancialPlanningQA";

function FinancialPlanningTool() {
  const { vcid } = useParams();
  const [locked, setLocked] = useState(false);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [dob, setdob] = useState("");
  const [expense, setexpense] = useState(5000);
  const [userimage, setuserimage] = useState(null);
  const [riskanalysis1, setrisksnalysis1] = useState("a");
  const [riskanalysis2, setrisksnalysis2] = useState("a");
  const [riskanalysis3, setrisksnalysis3] = useState("a");
  const [riskanalysis4, setrisksnalysis4] = useState("a");
  const [riskanalysis5, setrisksnalysis5] = useState("a");
  const [showgraph, setshowgraph] = useState(false);
  const [showcamera, setshowcamera] = useState(false);

  const pdfref = React.createRef();
  const pdfmobileref = React.createRef();

  const [isMobileDevice, setIsMobileDevice] = useState(window.innerWidth < 786);
  const videoConstraints = {
    width: 800,
    height: 800,
    facingMode: "user",
  };

  const [currentStep, setCurrentStep] = useState(1);

  const [debtresult, setdebtresult] = useState("0");
  const [equityresult, setequityresult] = useState("0");
  const [cashresult, setcashresult] = useState("0");
  const [remark, setremark] = useState("0");
  const [vcdata, setvcdata] = useState({});

  useEffect(() => {
    db.collection("users")
      .doc(vcid)
      .get()
      .then((vcdata) => {
        setvcdata(vcdata.data());
      });
  }, []);

  const [childeducation, setchildeducation] = useState({
    name: "Child Education",
    inflation: 5,
    rateofreturn: 12,
    afterhowmanyyears: 10,
    currentvalue: 1000000,
    futurevalue: 0,
    years: 0,
    monthlyinvestment: 0,
  });

  const newExpense = expense * 12;
  const [retirementanalysis, setretirementanalysis] = useState({
    name: "Retirement Analysis",
    inflation: 5,
    rateofreturn: 12,
    afterhowmanyyears: 10,
    currentvalue: newExpense,
    futurevalue: 0,
    years: 0,
    monthlyinvestment: 0,
  });

  const [weddinganalysis, setweddinganalysis] = useState({
    name: "Wedding Analysis",
    inflation: 5,
    rateofreturn: 12,
    afterhowmanyyears: 10,
    currentvalue: 1000000,
    futurevalue: 0,
    years: 0,
    monthlyinvestment: 0,
  });

  const [holidayanalysis, setholidayanalysis] = useState({
    name: "Holiday Analysis",
    inflation: 5,
    rateofreturn: 12,
    afterhowmanyyears: 10,
    currentvalue: 1000000,
    futurevalue: 0,
    years: 0,
    monthlyinvestment: 0,
  });

  const [homeanalysis, sethomeanalysis] = useState({
    name: "Home",
    inflation: 5,
    rateofreturn: 12,
    afterhowmanyyears: 10,
    currentvalue: 1000000,
    futurevalue: 0,
    years: 0,
    monthlyinvestment: 0,
  });

  const [emergencyanalysis, setemergencyanalysis] = useState({
    name: "Emergency",
    inflation: 5,
    rateofreturn: 12,
    afterhowmanyyears: 10,
    currentvalue: 1000000,
    futurevalue: 0,
    years: 0,
    monthlyinvestment: 0,
  });

  const goalRef = useRef(null);
  const graphRef = useRef(null);
  const reportRef = useRef(null);
  const answersref = useRef(null);
  const introref = useRef(null);

  useEffect(() => {
    let total = 0;
    if (riskanalysis1 === "a") {
      total = total + 5;
    }
    if (riskanalysis1 === "b") {
      total = total + 10;
    }
    if (riskanalysis1 === "c") {
      total = total + 15;
    }
    if (riskanalysis1 === "d") {
      total = total + 20;
    }
    if (riskanalysis1 === "e") {
      total = total + 25;
    }

    if (riskanalysis2 === "a") {
      total = total + 5;
    }
    if (riskanalysis2 === "b") {
      total = total + 10;
    }
    if (riskanalysis2 === "c") {
      total = total + 15;
    }
    if (riskanalysis2 === "d") {
      total = total + 20;
    }
    if (riskanalysis2 === "e") {
      total = total + 25;
    }

    if (riskanalysis3 === "a") {
      total = total + 5;
    }
    if (riskanalysis3 === "b") {
      total = total + 10;
    }
    if (riskanalysis3 === "c") {
      total = total + 15;
    }
    if (riskanalysis3 === "d") {
      total = total + 20;
    }
    if (riskanalysis3 === "e") {
      total = total + 25;
    }

    if (riskanalysis4 === "a") {
      total = total + 5;
    }
    if (riskanalysis4 === "b") {
      total = total + 10;
    }
    if (riskanalysis4 === "c") {
      total = total + 15;
    }
    if (riskanalysis4 === "d") {
      total = total + 20;
    }
    if (riskanalysis4 === "e") {
      total = total + 25;
    }

    if (riskanalysis5 === "a") {
      total = total + 5;
    }
    if (riskanalysis5 === "b") {
      total = total + 10;
    }
    if (riskanalysis5 === "c") {
      total = total + 15;
    }
    if (riskanalysis5 === "d") {
      total = total + 20;
    }
    if (riskanalysis5 === "e") {
      total = total + 25;
    }

    if (total >= 1 && total <= 35) {
      setremark("Very Conservative");
      setdebtresult(5);
      setequityresult(65);
      setcashresult(30);
    } else if (total >= 36 && total <= 55) {
      setremark("Conservative");
      setdebtresult(20);
      setequityresult(60);
      setcashresult(20);
    } else if (total >= 56 && total <= 75) {
      setremark("Moderate");
      setdebtresult(35);
      setequityresult(50);
      setcashresult(15);
    } else if (total >= 76 && total <= 100) {
      setremark("Aggressive");
      setdebtresult(60);
      setequityresult(30);
      setcashresult(10);
    } else {
      setremark("Very Aggressive");
      setdebtresult(80);
      setequityresult(10);
      setcashresult(10);
    }
  }, [
    riskanalysis1,
    riskanalysis2,
    riskanalysis3,
    riskanalysis4,
    riskanalysis5,
  ]);

  useEffect(() => {
    var a = 1 + childeducation.inflation / 100;
    var b = Math.pow(a, childeducation.afterhowmanyyears);
    var c = childeducation.currentvalue * b;

    var dd = 1 + childeducation.rateofreturn / 100;
    var e = 1 / 12;
    var bb = Math.pow(dd, e);
    var cc = bb - 1;

    var d = 1 + cc;
    var g = childeducation.afterhowmanyyears * 12;
    var f = Math.pow(d, g);
    var h = f - 1;
    var k = (c * cc) / h;
    if (
      childeducation.futurevalue !== c.toFixed(2) ||
      childeducation.monthlyinvestment !== k.toFixed(2)
    ) {
      setchildeducation({
        ...childeducation,
        futurevalue: c.toFixed(2),
        years: childeducation.afterhowmanyyears,
        monthlyinvestment: k.toFixed(2),
      });
    }
  }, [childeducation]);

  useEffect(() => {
    var a = 1 + retirementanalysis.inflation / 100;
    var b = Math.pow(a, retirementanalysis.afterhowmanyyears);
    var c = retirementanalysis.currentvalue * b;

    var dd = 1 + retirementanalysis.rateofreturn / 100;
    var e = 1 / 12;
    var bb = Math.pow(dd, e);
    var cc = bb - 1;

    var d = 1 + cc;
    var g = retirementanalysis.afterhowmanyyears * 12;
    var f = Math.pow(d, g);
    var h = f - 1;
    var k = (c * cc) / h;
    if (
      retirementanalysis.futurevalue !== c.toFixed(2) ||
      retirementanalysis.monthlyinvestment !== k.toFixed(2)
    ) {
      setretirementanalysis({
        ...retirementanalysis,
        futurevalue: c.toFixed(2),
        years: retirementanalysis.afterhowmanyyears,
        monthlyinvestment: k.toFixed(2),
      });
    }
  }, [retirementanalysis]);

  useEffect(() => {
    var a = 1 + weddinganalysis.inflation / 100;
    var b = Math.pow(a, weddinganalysis.afterhowmanyyears);
    var c = weddinganalysis.currentvalue * b;

    var dd = 1 + weddinganalysis.rateofreturn / 100;
    var e = 1 / 12;
    var bb = Math.pow(dd, e);
    var cc = bb - 1;

    var d = 1 + cc;
    var g = weddinganalysis.afterhowmanyyears * 12;
    var f = Math.pow(d, g);
    var h = f - 1;
    var k = (c * cc) / h;
    if (
      weddinganalysis.futurevalue !== c.toFixed(2) ||
      weddinganalysis.monthlyinvestment !== k.toFixed(2)
    ) {
      setweddinganalysis({
        ...weddinganalysis,
        futurevalue: c.toFixed(2),
        years: weddinganalysis.afterhowmanyyears,
        monthlyinvestment: k.toFixed(2),
      });
    }
  }, [weddinganalysis]);

  useEffect(() => {
    var a = 1 + holidayanalysis.inflation / 100;
    var b = Math.pow(a, holidayanalysis.afterhowmanyyears);
    var c = holidayanalysis.currentvalue * b;

    var dd = 1 + holidayanalysis.rateofreturn / 100;
    var e = 1 / 12;
    var bb = Math.pow(dd, e);
    var cc = bb - 1;

    var d = 1 + cc;
    var g = holidayanalysis.afterhowmanyyears * 12;
    var f = Math.pow(d, g);
    var h = f - 1;
    var k = (c * cc) / h;
    if (
      holidayanalysis.futurevalue !== c.toFixed(2) ||
      holidayanalysis.monthlyinvestment !== k.toFixed(2)
    ) {
      setholidayanalysis({
        ...holidayanalysis,
        futurevalue: c.toFixed(2),
        years: holidayanalysis.afterhowmanyyears,
        monthlyinvestment: k.toFixed(2),
      });
    }
  }, [holidayanalysis]);

  useEffect(() => {
    var a = 1 + homeanalysis.inflation / 100;
    var b = Math.pow(a, homeanalysis.afterhowmanyyears);
    var c = homeanalysis.currentvalue * b;

    var dd = 1 + homeanalysis.rateofreturn / 100;
    var e = 1 / 12;
    var bb = Math.pow(dd, e);
    var cc = bb - 1;

    var d = 1 + cc;
    var g = homeanalysis.afterhowmanyyears * 12;
    var f = Math.pow(d, g);
    var h = f - 1;
    var k = (c * cc) / h;
    if (
      homeanalysis.futurevalue !== c.toFixed(2) ||
      homeanalysis.monthlyinvestment !== k.toFixed(2)
    ) {
      sethomeanalysis({
        ...homeanalysis,
        futurevalue: c.toFixed(2),
        years: homeanalysis.afterhowmanyyears,
        monthlyinvestment: k.toFixed(2),
      });
    }
  }, [homeanalysis]);

  useEffect(() => {
    var a = 1 + emergencyanalysis.inflation / 100;
    var b = Math.pow(a, emergencyanalysis.afterhowmanyyears);
    var c = emergencyanalysis.currentvalue * b;

    var dd = 1 + emergencyanalysis.rateofreturn / 100;
    var e = 1 / 12;
    var bb = Math.pow(dd, e);
    var cc = bb - 1;

    var d = 1 + cc;
    var g = emergencyanalysis.afterhowmanyyears * 12;
    var f = Math.pow(d, g);
    var h = f - 1;
    var k = (c * cc) / h;
    if (
      emergencyanalysis.futurevalue !== c.toFixed(2) ||
      emergencyanalysis.monthlyinvestment !== k.toFixed(2)
    ) {
      setemergencyanalysis({
        ...emergencyanalysis,
        futurevalue: c.toFixed(2),
        years: emergencyanalysis.afterhowmanyyears,
        monthlyinvestment: k.toFixed(2),
      });
    }
  }, [emergencyanalysis]);

  const executeScroll = () => {
    if (name === "") {
      alert("Please enter name");
    } else if (email === "") {
      alert("Please enter email");
    } else if (mobile === "") {
      alert("Please enter mobile");
    } else if (mobile.length !== 10) {
      alert("Mobile number should be 10 digit numbers");
    } else if (dob === "") {
      alert("Please Enter Date of Birth");
    } else {
      setshowgraph(true);
      setCurrentStep(3);
      goalRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  };
  const executeScroll2 = () => {
    // setCurrentStep(4);

    // graphRef.current.scrollIntoView({
    //   behavior: "smooth",
    //   block: "start",
    //   inline: "start",
    // });
    generatereport();

    setCurrentStep(5);
    reportRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  const executeScroll3 = () => {
    setCurrentStep(5);
    generatereport();
    reportRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  const backtograph = () => {
    // alert("back graph");
    setCurrentStep(4);

    graphRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  const backtogoal = () => {
    // alert("back goal");
    setCurrentStep(3);

    goalRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  const backtointro = () => {
    // alert("back intro");
    setCurrentStep(1);

    introref.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  const executeScroll4 = () => {
    if (name === "") {
      alert("Please enter name");
    } else if (email === "") {
      alert("Please enter email");
    } else if (mobile === "") {
      alert("Please enter mobile");
    } else if (mobile.length !== 10) {
      alert("Mobile number should be 10 digit numbers");
    } else if (dob === "") {
      alert("Please Enter Date of Birth");
    } else {
      setshowgraph(true);
      setCurrentStep(2);
      answersref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
  };

  const downloadpdf = () => {
    const input = document.getElementById("report");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      // pdf.output('dataurlnewwindow');
      pdf.save("download.pdf");
    });
  };

  const generatereport = () => {
    if (locked === false) {
      var id = db.collection("financialplanning").doc().id;
      db.collection("financialplanning")
        .doc(id)
        .set({
          name: name,
          email: email,
          mobile: mobile,
          dob: dob,
          monthlyexpense: expense,
          date: Date(),
          vcid: vcid,
          riskanalysis1: riskanalysis1,
          riskanalysis2: riskanalysis2,
          riskanalysis3: riskanalysis3,
          riskanalysis4: riskanalysis4,
          riskanalysis5: riskanalysis5,
          childeducation: childeducation,
          retirementanalysis: retirementanalysis,
          weddinganalysis: weddinganalysis,
          holidayanalysis: holidayanalysis,
          homeanalysis: homeanalysis,
          emergencyanalysis: emergencyanalysis,
        })
        .then((dd) => {
          setLocked(true);
        });
    }
  };

  const shouldshow = () => {
    if (window.innerWidth > 786) {
      if (currentStep === 1) {
        return true;
      }
    } else if (currentStep === 2) {
      return true;
    }
    return false;
  };

  return (
    <div className="financialplanningtool">
      <Header />
      <h2>Sustainability Matrix</h2>
      <div className="allsections">
        <div
          style={{ display: currentStep === 1 ? "block" : "none" }}
          className="section1"
          ref={introref}
        >
          <span className="sectionnumber">1</span>
          <h3 class="sectiontitle">Lets get started by knowing about you</h3>
          {/* <h3 class="sectiontitle">Upload your picture (optional)</h3> */}
          {userimage === null ? (
            <img
              src={User}
              alt=""
              width={120}
              height={120}
              style={{
                objectFit: "contain",
                backgroundColor: "white",
                borderRadius: 10,
                display: "block",
                marginBottom: 20,
                padding: 10,
              }}
            />
          ) : (
            <img
              src={userimage}
              alt=""
              width={120}
              height={120}
              style={{
                objectFit: "contain",
                backgroundColor: "white",
                borderRadius: 10,
                display: "block",
                marginBottom: 20,
                padding: 10,
              }}
            />
          )}
          <input
            type="file"
            id="img"
            name="img"
            accept="image/*"
            onChange={(e) => {
              if (e.target.files && e.target.files[0]) {
                setuserimage(URL.createObjectURL(e.target.files[0]));
              }
            }}
          />
          <h5>OR</h5>
          <button
            style={{
              padding: 10,
              width: 300,
              marginTop: 10,
              marginBottom: 20,
              fontSize: "1rem",
            }}
            onClick={() => setshowcamera(true)}
          >
            Upload from Webcam
          </button>
          {showcamera && (
            <div className="webcamdiv">
              <button onClick={() => setshowcamera(false)}>Close</button>
              <Webcam
                audio={false}
                height={800}
                screenshotFormat="image/jpeg"
                width={800}
                videoConstraints={videoConstraints}
              >
                {({ getScreenshot }) => (
                  <button
                    onClick={() => {
                      const imageSrc = getScreenshot();
                      setuserimage(imageSrc);
                      setshowcamera(false);
                    }}
                  >
                    Capture photo
                  </button>
                )}
              </Webcam>
            </div>
          )}
          <form>
            <h5>Name</h5>
            <input
              type="text"
              value={name}
              onChange={(e) => {
                setname(e.target.value);
              }}
            />
            <h5>Email</h5>
            <input
              type="email"
              value={email}
              onChange={(e) => {
                setemail(e.target.value);
              }}
            />
            <h5>Mobile</h5>
            <input
              type="number"
              value={mobile}
              onChange={(e) => {
                setmobile(e.target.value);
              }}
            />
            <h5>Date of Birth</h5>
            <input
              type="date"
              value={dob}
              onChange={(e) => {
                setdob(e.target.value);
              }}
            />
            <h5>Monthly Expense : {expense}</h5>
            <input
              type="number"
              value={expense}
              onChange={(e) => {
                setexpense(e.target.value);
                setretirementanalysis({
                  ...retirementanalysis,
                  currentvalue: e.target.value * 200,
                });
              }}
            />
          </form>
          <button
            id="mobileonly"
            className="nextbtn"
            onClick={() => executeScroll4()}
          >
            NEXT
          </button>
        </div>

        <div
          style={{
            display: shouldshow() ? "block" : "none",
          }}
          className="section3"
          ref={answersref}
        >
          <div>
            <span className="sectionnumber">2</span>
            <h3 class="sectiontitle">Answer the following questions</h3>
            <div>
              <h5>{FinacialPlanningQA.question1}</h5>
              <select
                id="riskprofileselect1"
                onChange={(e) => setrisksnalysis1(e.target.value)}
              >
                <option value="a" selected={riskanalysis1 === "a"}>
                  {FinacialPlanningQA.optionQ1A}
                </option>
                <option value="b" selected={riskanalysis1 === "b"}>
                  {FinacialPlanningQA.optionQ1B}
                </option>
                <option value="c" selected={riskanalysis1 === "c"}>
                  {FinacialPlanningQA.optionQ1C}
                </option>
                <option value="d" selected={riskanalysis1 === "d"}>
                  {FinacialPlanningQA.optionQ1D}
                </option>
                <option value="e" selected={riskanalysis1 === "e"}>
                  {FinacialPlanningQA.optionQ1E}
                </option>
              </select>
            </div>
            <div>
              <h5>{FinacialPlanningQA.question2}</h5>

              <select
                id="riskprofileselect2"
                onChange={(e) => setrisksnalysis2(e.target.value)}
              >
                <option value="a" selected={riskanalysis2 === "a"}>
                  {FinacialPlanningQA.optionQ2A}
                </option>
                <option value="b" selected={riskanalysis2 === "b"}>
                  {FinacialPlanningQA.optionQ2B}
                </option>
                <option value="c" selected={riskanalysis2 === "c"}>
                  {FinacialPlanningQA.optionQ2C}
                </option>
                <option value="d" selected={riskanalysis2 === "d"}>
                  {FinacialPlanningQA.optionQ2D}
                </option>
                <option value="e" selected={riskanalysis2 === "e"}>
                  {FinacialPlanningQA.optionQ2E}
                </option>
              </select>
            </div>
            <div>
              <h5>{FinacialPlanningQA.question3}</h5>
              <select
                id="riskprofileselect3"
                onChange={(e) => setrisksnalysis3(e.target.value)}
              >
                <option value="a" selected={riskanalysis3 === "a"}>
                  {FinacialPlanningQA.optionQ3A}
                </option>
                <option value="b" selected={riskanalysis3 === "b"}>
                  {FinacialPlanningQA.optionQ3B}
                </option>
                <option value="c" selected={riskanalysis3 === "c"}>
                  {FinacialPlanningQA.optionQ3C}
                </option>
                <option value="d" selected={riskanalysis3 === "d"}>
                  {FinacialPlanningQA.optionQ3D}
                </option>
                <option value="e" selected={riskanalysis3 === "e"}>
                  {FinacialPlanningQA.optionQ3E}
                </option>
              </select>
            </div>
            <div>
              <h5>{FinacialPlanningQA.question4}</h5>

              <select
                id="riskprofileselect4"
                onChange={(e) => setrisksnalysis4(e.target.value)}
              >
                <option value="a" selected={riskanalysis4 === "a"}>
                  {FinacialPlanningQA.optionQ4A}
                </option>
                <option value="b" selected={riskanalysis4 === "b"}>
                  {FinacialPlanningQA.optionQ4B}
                </option>
                <option value="c" selected={riskanalysis4 === "c"}>
                  {FinacialPlanningQA.optionQ4C}
                </option>
                <option value="d" selected={riskanalysis4 === "d"}>
                  {FinacialPlanningQA.optionQ4D}
                </option>
                <option value="e" selected={riskanalysis4 === "e"}>
                  {FinacialPlanningQA.optionQ4E}
                </option>
              </select>
            </div>
            <div>
              <h5>{FinacialPlanningQA.question5}</h5>

              <select
                id="riskprofileselect5"
                onChange={(e) => setrisksnalysis5(e.target.value)}
              >
                <option value="a" selected={riskanalysis5 === "a"}>
                  {FinacialPlanningQA.optionQ5A}
                </option>
                <option value="b" selected={riskanalysis5 === "b"}>
                  {FinacialPlanningQA.optionQ5B}
                </option>
                <option value="c" selected={riskanalysis5 === "c"}>
                  {FinacialPlanningQA.optionQ5C}
                </option>
                <option value="d" selected={riskanalysis5 === "d"}>
                  {FinacialPlanningQA.optionQ5D}
                </option>
                <option value="e" selected={riskanalysis5 === "e"}>
                  {FinacialPlanningQA.optionQ5E}
                </option>
              </select>
            </div>
          </div>
          <div style={{ display: "flex", marginTop: 20 }}>
            <h3>Equity: {debtresult}%</h3>
            <h3>Debt: {equityresult}%</h3>
            <h3>Cash: {cashresult}%</h3>
          </div>
          <button
            className="nextbtn"
            onClick={() => {
              executeScroll();
            }}
          >
            NEXT
          </button>
          <button
            className="nextbtn"
            onClick={() => {
              setdebtresult(0);
              setequityresult(0);
              setcashresult(0);
              executeScroll();
            }}
          >
            Skip
          </button>
        </div>

        <div
          style={{ display: currentStep === 3 ? "block" : "none" }}
          className="section4"
          ref={goalRef}
        >
          <div>
            <span className="sectionnumber">4</span>
            <h3 class="sectiontitle">Sustainability Matrix</h3>
            <div className="allrisks">
              <div className="eachrisk">
                <div>
                  <h6>Type</h6>
                  <input
                    type="text"
                    value={childeducation.name}
                    onChange={(e) => {
                      setchildeducation({
                        ...childeducation,
                        name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  <h6>Inflation</h6>
                  <input
                    type="number"
                    value={childeducation.inflation}
                    onChange={(e) => {
                      setchildeducation({
                        ...childeducation,
                        inflation: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  <h6>Rate of Return</h6>
                  <input
                    type="number"
                    value={childeducation.rateofreturn}
                    onChange={(e) => {
                      setchildeducation({
                        ...childeducation,
                        rateofreturn: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  <h6>After How many years</h6>
                  <input
                    type="number"
                    value={childeducation.afterhowmanyyears}
                    onChange={(e) => {
                      setchildeducation({
                        ...childeducation,
                        afterhowmanyyears: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  <h6>Current Value</h6>
                  <input
                    type="number"
                    value={childeducation.currentvalue}
                    onChange={(e) => {
                      setchildeducation({
                        ...childeducation,
                        currentvalue: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  <h6>Future Value</h6>
                  <input
                    type="number"
                    disabled
                    value={childeducation.futurevalue}
                    onChange={(e) => {
                      setchildeducation({
                        ...childeducation,
                        futurevalue: e.target.value,
                      });
                    }}
                  />
                </div>
                {/* <div>
                  <h6>Years</h6>
                  <input
                    disabled
                    type="number"
                    value={childeducation.years}
                    onChange={(e) => {
                      setchildeducation({
                        ...childeducation,
                        years: e.target.value,
                      });
                    }}
                  />
                </div> */}
                <div>
                  <h6>Monthly Investment</h6>
                  <input
                    disabled
                    type="number"
                    value={childeducation.monthlyinvestment}
                    onChange={(e) => {
                      setchildeducation({
                        ...childeducation,
                        monthlyinvestment: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="eachrisk">
                <div>
                  {/* <h6>Type</h6> */}
                  <input
                    type="text"
                    disabled
                    value={retirementanalysis.name}
                    onChange={(e) => {
                      setretirementanalysis({
                        ...retirementanalysis,
                        name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>Inflation</h6> */}
                  <input
                    type="number"
                    value={retirementanalysis.inflation}
                    onChange={(e) => {
                      setretirementanalysis({
                        ...retirementanalysis,
                        inflation: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>Rate of Return</h6> */}
                  <input
                    type="number"
                    value={retirementanalysis.rateofreturn}
                    onChange={(e) => {
                      setretirementanalysis({
                        ...retirementanalysis,
                        rateofreturn: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>After How many years</h6> */}
                  <input
                    type="number"
                    value={retirementanalysis.afterhowmanyyears}
                    onChange={(e) => {
                      setretirementanalysis({
                        ...retirementanalysis,
                        afterhowmanyyears: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>Current Value</h6> */}
                  <input
                    type="number"
                    value={retirementanalysis.currentvalue}
                    onChange={(e) => {
                      setretirementanalysis({
                        ...retirementanalysis,
                        currentvalue: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>Future Value</h6> */}
                  <input
                    type="number"
                    disabled
                    value={retirementanalysis.futurevalue}
                    onChange={(e) => {
                      setretirementanalysis({
                        ...retirementanalysis,
                        futurevalue: e.target.value,
                      });
                    }}
                  />
                </div>
                {/* <div>
                  <h6>Years</h6>
                  <input
                    type="number"
                    disabled
                    value={retirementanalysis.years}
                    onChange={(e) => {
                      setretirementanalysis({
                        ...retirementanalysis,
                        years: e.target.value,
                      });
                    }}
                  />
                </div> */}
                <div>
                  {/* <h6>Monthly Investment</h6> */}
                  <input
                    type="number"
                    disabled
                    value={retirementanalysis.monthlyinvestment}
                    onChange={(e) => {
                      setretirementanalysis({
                        ...retirementanalysis,
                        monthlyinvestment: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="eachrisk">
                <div>
                  {/* <h6>Type</h6> */}
                  <input
                    type="text"
                    value={weddinganalysis.name}
                    onChange={(e) => {
                      setweddinganalysis({
                        ...weddinganalysis,
                        name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>Inflation</h6> */}
                  <input
                    type="number"
                    value={weddinganalysis.inflation}
                    onChange={(e) => {
                      setweddinganalysis({
                        ...weddinganalysis,
                        inflation: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>Rate of Return</h6> */}
                  <input
                    type="number"
                    value={weddinganalysis.rateofreturn}
                    onChange={(e) => {
                      setweddinganalysis({
                        ...weddinganalysis,
                        rateofreturn: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>After How many years</h6> */}
                  <input
                    type="number"
                    value={weddinganalysis.afterhowmanyyears}
                    onChange={(e) => {
                      setweddinganalysis({
                        ...weddinganalysis,
                        afterhowmanyyears: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>Current Value</h6> */}
                  <input
                    type="number"
                    value={weddinganalysis.currentvalue}
                    onChange={(e) => {
                      setweddinganalysis({
                        ...weddinganalysis,
                        currentvalue: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>Future Value</h6> */}
                  <input
                    type="number"
                    disabled
                    value={weddinganalysis.futurevalue}
                    onChange={(e) => {
                      setweddinganalysis({
                        ...weddinganalysis,
                        futurevalue: e.target.value,
                      });
                    }}
                  />
                </div>
                {/* <div>
                  <h6>Years</h6>
                  <input
                    type="number"
                    disabled
                    value={weddinganalysis.years}
                    onChange={(e) => {
                      setweddinganalysis({
                        ...weddinganalysis,
                        years: e.target.value,
                      });
                    }}
                  />
                </div> */}
                <div>
                  {/* <h6>Monthly Investment</h6> */}
                  <input
                    type="number"
                    disabled
                    value={weddinganalysis.monthlyinvestment}
                    onChange={(e) => {
                      setweddinganalysis({
                        ...weddinganalysis,
                        monthlyinvestment: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="eachrisk">
                <div>
                  {/* <h6>Type</h6> */}
                  <input
                    type="text"
                    value={holidayanalysis.name}
                    onChange={(e) => {
                      setholidayanalysis({
                        ...holidayanalysis,
                        name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>Inflation</h6> */}
                  <input
                    type="number"
                    value={holidayanalysis.inflation}
                    onChange={(e) => {
                      setholidayanalysis({
                        ...holidayanalysis,
                        inflation: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>Rate of Return</h6> */}
                  <input
                    type="number"
                    value={holidayanalysis.rateofreturn}
                    onChange={(e) => {
                      setholidayanalysis({
                        ...holidayanalysis,
                        rateofreturn: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>After How many years</h6> */}
                  <input
                    type="number"
                    value={holidayanalysis.afterhowmanyyears}
                    onChange={(e) => {
                      setholidayanalysis({
                        ...holidayanalysis,
                        afterhowmanyyears: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>Current Value</h6> */}
                  <input
                    type="number"
                    value={holidayanalysis.currentvalue}
                    onChange={(e) => {
                      setholidayanalysis({
                        ...holidayanalysis,
                        currentvalue: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>Future Value</h6> */}
                  <input
                    type="number"
                    disabled
                    value={holidayanalysis.futurevalue}
                    onChange={(e) => {
                      setholidayanalysis({
                        ...holidayanalysis,
                        futurevalue: e.target.value,
                      });
                    }}
                  />
                </div>
                {/* <div>
                  <h6>Years</h6>
                  <input
                    type="number"
                    disabled
                    value={holidayanalysis.years}
                    onChange={(e) => {
                      setholidayanalysis({
                        ...holidayanalysis,
                        years: e.target.value,
                      });
                    }}
                  />
                </div> */}
                <div>
                  {/* <h6>Monthly Investment</h6> */}
                  <input
                    type="number"
                    disabled
                    value={holidayanalysis.monthlyinvestment}
                    onChange={(e) => {
                      setholidayanalysis({
                        ...holidayanalysis,
                        monthlyinvestment: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="eachrisk">
                <div>
                  {/* <h6>Type</h6> */}
                  <input
                    type="text"
                    value={homeanalysis.name}
                    onChange={(e) => {
                      sethomeanalysis({
                        ...homeanalysis,
                        name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>Inflation</h6> */}
                  <input
                    type="number"
                    value={homeanalysis.inflation}
                    onChange={(e) => {
                      sethomeanalysis({
                        ...homeanalysis,
                        inflation: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>Rate of Return</h6> */}
                  <input
                    type="number"
                    value={homeanalysis.rateofreturn}
                    onChange={(e) => {
                      sethomeanalysis({
                        ...homeanalysis,
                        rateofreturn: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>After How many years</h6> */}
                  <input
                    type="number"
                    value={homeanalysis.afterhowmanyyears}
                    onChange={(e) => {
                      sethomeanalysis({
                        ...homeanalysis,
                        afterhowmanyyears: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>Current Value</h6> */}
                  <input
                    type="number"
                    value={homeanalysis.currentvalue}
                    onChange={(e) => {
                      sethomeanalysis({
                        ...homeanalysis,
                        currentvalue: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>Future Value</h6> */}
                  <input
                    type="number"
                    disabled
                    value={homeanalysis.futurevalue}
                    onChange={(e) => {
                      sethomeanalysis({
                        ...homeanalysis,
                        futurevalue: e.target.value,
                      });
                    }}
                  />
                </div>
                {/* <div>
                  <h6>Years</h6>
                  <input
                    type="number"
                    disabled
                    value={homeanalysis.years}
                    onChange={(e) => {
                      sethomeanalysis({
                        ...homeanalysis,
                        years: e.target.value,
                      });
                    }}
                  />
                </div> */}
                <div>
                  {/* <h6>Monthly Investment</h6> */}
                  <input
                    type="number"
                    disabled
                    value={homeanalysis.monthlyinvestment}
                    onChange={(e) => {
                      sethomeanalysis({
                        ...homeanalysis,
                        monthlyinvestment: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="eachrisk">
                <div>
                  {/* <h6>Type</h6> */}
                  <input
                    type="text"
                    value={emergencyanalysis.name}
                    onChange={(e) => {
                      setemergencyanalysis({
                        ...emergencyanalysis,
                        name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>Inflation</h6> */}
                  <input
                    type="number"
                    value={emergencyanalysis.inflation}
                    onChange={(e) => {
                      setemergencyanalysis({
                        ...emergencyanalysis,
                        inflation: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>Rate of Return</h6> */}
                  <input
                    type="number"
                    value={emergencyanalysis.rateofreturn}
                    onChange={(e) => {
                      setemergencyanalysis({
                        ...emergencyanalysis,
                        rateofreturn: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>After How many years</h6> */}
                  <input
                    type="number"
                    value={emergencyanalysis.afterhowmanyyears}
                    onChange={(e) => {
                      setemergencyanalysis({
                        ...emergencyanalysis,
                        afterhowmanyyears: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>Current Value</h6> */}
                  <input
                    type="number"
                    value={emergencyanalysis.currentvalue}
                    onChange={(e) => {
                      setemergencyanalysis({
                        ...emergencyanalysis,
                        currentvalue: e.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  {/* <h6>Future Value</h6> */}
                  <input
                    type="number"
                    disabled
                    value={emergencyanalysis.futurevalue}
                    onChange={(e) => {
                      setemergencyanalysis({
                        ...emergencyanalysis,
                        futurevalue: e.target.value,
                      });
                    }}
                  />
                </div>
                {/* <div>
                  <h6>Years</h6>
                  <input
                    type="number"
                    disabled
                    value={emergencyanalysis.years}
                    onChange={(e) => {
                      setemergencyanalysis({
                        ...emergencyanalysis,
                        years: e.target.value,
                      });
                    }}
                  />
                </div> */}
                <div>
                  {/* <h6>Monthly Investment</h6> */}
                  <input
                    type="number"
                    disabled
                    value={emergencyanalysis.monthlyinvestment}
                    onChange={(e) => {
                      setemergencyanalysis({
                        ...emergencyanalysis,
                        monthlyinvestment: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "50%" }}>
            <button className="nextbtn" onClick={backtointro}>
              Back
            </button>
            <button className="nextbtn" onClick={executeScroll2}>
              NEXT
            </button>
          </div>
        </div>

        <div
          style={{ display: currentStep === 4 ? "flex" : "none" }}
          className="section5"
          ref={graphRef}
        >
          <span className="sectionnumber">5</span>
          <h3 class="sectiontitle">Your Summary</h3>
          {showgraph === true && (
            <div className="grapharea">
              <ShowGraph
                minified={false}
                childeducation={childeducation}
                retirementanalysis={retirementanalysis}
                weddinganalysis={weddinganalysis}
                holidayanalysis={holidayanalysis}
                homeanalysis={homeanalysis}
                emergencyanalysis={emergencyanalysis}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <button className="nextbutton" onClick={backtogoal}>
                  Back
                </button>
                <button className="nextbutton" onClick={executeScroll3}>
                  Next
                </button>
              </div>
            </div>
          )}
        </div>

        <div
          style={{ display: currentStep === 5 ? "flex" : "none" }}
          className="section6"
          ref={reportRef}
        >
          <span className="sectionnumber">6</span>
          {/* <button onClick={downloadpdf}>Download as PDF</button> */}

          <div className="btnsholder">
            <button onClick={backtograph}>Back</button>
            <ReactToPdf
              targetRef={pdfmobileref}
              filename="financialPlan.pdf"
              x={0}
              y={0}
              scale={0.95}
              onComplete={() => {
                document.getElementById("mobilepdf").style.display = "none";
              }}
            >
              {({ toPdf }) => (
                <button
                  onClick={() => {
                    document.getElementById("mobilepdf").style.display =
                      "block";
                    toPdf();
                  }}
                >
                  Download as PDF
                </button>
              )}
            </ReactToPdf>
          </div>

          <div className="report" ref={pdfref} id="report">
            <div className="row introrow">
              <div className="each">
                <h3>Sustainability Matrix for {name?.toUpperCase()}</h3>
                <h5>
                  {email} | {mobile}
                </h5>
                <h5>DOB: {dob}</h5>
              </div>
              <div className="each">
                {userimage === null ? (
                  <img
                    src={User}
                    alt=""
                    width={120}
                    height={120}
                    style={{
                      objectFit: "contain",
                      backgroundColor: "white",
                      borderRadius: 10,
                      display: "block",
                      padding: 10,
                    }}
                  />
                ) : (
                  <img
                    src={userimage}
                    alt=""
                    width={120}
                    height={120}
                    style={{
                      objectFit: "contain",
                      backgroundColor: "white",
                      borderRadius: 10,
                      display: "block",
                      padding: 10,
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="bubble">
                <h4>{childeducation.name}</h4>
                <h5>
                  Future Value Rs.{" "}
                  {help.formatNumbers(childeducation.futurevalue, 0)}
                </h5>
                <h5>
                  Monthly Savings Rs.{" "}
                  {help.formatNumbers(childeducation.monthlyinvestment, 0)}
                </h5>
                <h5>Total Time {childeducation.afterhowmanyyears} Yrs</h5>
              </div>
              <div className="bubble">
                <h4>{retirementanalysis.name}</h4>
                <h5>
                  Future Value Rs.{" "}
                  {help.formatNumbers(retirementanalysis.futurevalue, 0)}
                </h5>
                <h5>
                  Monthly Savings Rs.{" "}
                  {help.formatNumbers(retirementanalysis.monthlyinvestment, 0)}
                </h5>
                <h5>Total Time {retirementanalysis.afterhowmanyyears} Yrs</h5>
              </div>
              <div className="bubble">
                <h4>{weddinganalysis.name}</h4>
                <h5>
                  Future Value Rs.{" "}
                  {help.formatNumbers(weddinganalysis.futurevalue, 0)}
                </h5>
                <h5>
                  Monthly Savings Rs.{" "}
                  {help.formatNumbers(weddinganalysis.monthlyinvestment, 0)}
                </h5>
                <h5>Total Time {weddinganalysis.afterhowmanyyears} Yrs</h5>
              </div>
              <div className="bubble">
                <h4>{holidayanalysis.name}</h4>
                <h5>
                  Future Value Rs.{" "}
                  {help.formatNumbers(holidayanalysis.futurevalue, 0)}
                </h5>
                <h5>
                  Monthly Savings Rs.{" "}
                  {help.formatNumbers(holidayanalysis.monthlyinvestment, 0)}
                </h5>
                <h5>Total Time {holidayanalysis.afterhowmanyyears} Yrs</h5>
              </div>
              <div className="bubble">
                <h4>{homeanalysis.name}</h4>
                <h5>
                  Future Value Rs.{" "}
                  {help.formatNumbers(homeanalysis.futurevalue, 0)}
                </h5>
                <h5>
                  Monthly Savings Rs.{" "}
                  {help.formatNumbers(homeanalysis.monthlyinvestment, 0)}
                </h5>
                <h5>Total Time {homeanalysis.afterhowmanyyears} Yrs</h5>
              </div>
              <div className="bubble">
                <h4>{emergencyanalysis.name}</h4>
                <h5>
                  Future Value Rs.{" "}
                  {help.formatNumbers(emergencyanalysis.futurevalue, 0)}
                </h5>
                <h5>
                  Monthly Savings Rs.{" "}
                  {help.formatNumbers(emergencyanalysis.monthlyinvestment, 0)}
                </h5>
                <h5>Total Time {emergencyanalysis.afterhowmanyyears} Yrs</h5>
              </div>
            </div>
            <div className="row">
              <table>
                <thead>
                  <tr>
                    <th>Analysis</th>
                    <th>Future Value</th>
                    <th>Monthly Investment</th>
                    <th>Inflation</th>
                    <th>Rate of Return</th>
                    <th>Years</th>
                    <th>Current Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{childeducation.name}</td>
                    <td>
                      Rs. {help.formatNumbers(childeducation.futurevalue, 0)}
                    </td>
                    <td>
                      Rs.{" "}
                      {help.formatNumbers(childeducation.monthlyinvestment, 0)}
                    </td>
                    <td>{childeducation.inflation} %</td>
                    <td>{childeducation.rateofreturn} %</td>
                    <td>{childeducation.afterhowmanyyears} Years</td>
                    <td>
                      Rs. {help.formatNumbers(childeducation.currentvalue, 0)}
                    </td>
                  </tr>
                  <tr>
                    <td>{retirementanalysis.name}</td>
                    <td>
                      Rs.{" "}
                      {help.formatNumbers(retirementanalysis.futurevalue, 0)}
                    </td>
                    <td>
                      Rs.{" "}
                      {help.formatNumbers(
                        retirementanalysis.monthlyinvestment,
                        0
                      )}
                    </td>
                    <td>{retirementanalysis.inflation} %</td>
                    <td>{retirementanalysis.rateofreturn} %</td>
                    <td>{retirementanalysis.afterhowmanyyears} Years</td>
                    <td>
                      Rs.{" "}
                      {help.formatNumbers(retirementanalysis.currentvalue, 0)}
                    </td>
                  </tr>
                  <tr>
                    <td>{weddinganalysis.name}</td>
                    <td>
                      Rs. {help.formatNumbers(weddinganalysis.futurevalue, 0)}
                    </td>
                    <td>
                      Rs.{" "}
                      {help.formatNumbers(weddinganalysis.monthlyinvestment, 0)}
                    </td>
                    <td>{weddinganalysis.inflation} %</td>
                    <td>{weddinganalysis.rateofreturn} %</td>
                    <td>{weddinganalysis.afterhowmanyyears} Years</td>
                    <td>
                      Rs. {help.formatNumbers(weddinganalysis.currentvalue, 0)}
                    </td>
                  </tr>
                  <tr>
                    <td>{holidayanalysis.name}</td>
                    <td>
                      Rs. {help.formatNumbers(holidayanalysis.futurevalue, 0)}
                    </td>
                    <td>
                      Rs.{" "}
                      {help.formatNumbers(holidayanalysis.monthlyinvestment, 0)}
                    </td>
                    <td>{holidayanalysis.inflation} %</td>
                    <td>{holidayanalysis.rateofreturn} %</td>
                    <td>{holidayanalysis.afterhowmanyyears} Years</td>
                    <td>
                      Rs. {help.formatNumbers(holidayanalysis.currentvalue, 0)}
                    </td>
                  </tr>
                  <tr>
                    <td>{homeanalysis.name}</td>
                    <td>
                      Rs. {help.formatNumbers(homeanalysis.futurevalue, 0)}
                    </td>
                    <td>
                      Rs.{" "}
                      {help.formatNumbers(homeanalysis.monthlyinvestment, 0)}
                    </td>
                    <td>{homeanalysis.inflation} %</td>
                    <td>{homeanalysis.rateofreturn} %</td>
                    <td>{homeanalysis.afterhowmanyyears} Years</td>
                    <td>
                      Rs. {help.formatNumbers(homeanalysis.currentvalue, 0)}
                    </td>
                  </tr>
                  <tr>
                    <td>{emergencyanalysis.name}</td>
                    <td>
                      Rs. {help.formatNumbers(emergencyanalysis.futurevalue, 0)}
                    </td>
                    <td>
                      Rs.{" "}
                      {help.formatNumbers(
                        emergencyanalysis.monthlyinvestment,
                        0
                      )}
                    </td>
                    <td>{emergencyanalysis.inflation} %</td>
                    <td>{emergencyanalysis.rateofreturn} %</td>
                    <td>{emergencyanalysis.afterhowmanyyears} Years</td>
                    <td>
                      Rs.{" "}
                      {help.formatNumbers(emergencyanalysis.currentvalue, 0)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="mobilereport" ref={pdfmobileref} id="mobilepdf">
            <div className="row introrow">
              <div className="each">
                <h3>Sustainability Matrix for {name}</h3>
                <h5>
                  {email} | {mobile}
                </h5>
                <h5>DOB: {dob}</h5>
              </div>
              <div className="each">
                {userimage === null ? (
                  <img
                    src={User}
                    alt=""
                    width={120}
                    height={120}
                    style={{
                      objectFit: "contain",
                      backgroundColor: "white",
                      borderRadius: 10,
                      display: "block",
                      padding: 10,
                    }}
                  />
                ) : (
                  <img
                    src={userimage}
                    alt=""
                    width={120}
                    height={120}
                    style={{
                      objectFit: "contain",
                      backgroundColor: "white",
                      borderRadius: 10,
                      display: "block",
                      padding: 10,
                    }}
                  />
                )}
              </div>
            </div>
            <div className="row">
              <div className="bubble">
                <h4>{childeducation.name}</h4>
                <h5>
                  Future Value Rs.{" "}
                  {help.formatNumbers(childeducation.futurevalue, 0)}
                </h5>
                <h5>
                  Monthly Savings Rs.{" "}
                  {help.formatNumbers(childeducation.monthlyinvestment, 0)}
                </h5>
                <h5>Total Time {childeducation.afterhowmanyyears} Yrs</h5>
              </div>
              <div className="bubble">
                <h4>{retirementanalysis.name}</h4>
                <h5>
                  Future Value Rs.{" "}
                  {help.formatNumbers(retirementanalysis.futurevalue, 0)}
                </h5>
                <h5>
                  Monthly Savings Rs.{" "}
                  {help.formatNumbers(retirementanalysis.monthlyinvestment, 0)}
                </h5>
                <h5>Total Time {retirementanalysis.afterhowmanyyears} Yrs</h5>
              </div>
              <div className="bubble">
                <h4>{weddinganalysis.name}</h4>
                <h5>
                  Future Value Rs.{" "}
                  {help.formatNumbers(weddinganalysis.futurevalue, 0)}
                </h5>
                <h5>
                  Monthly Savings Rs.{" "}
                  {help.formatNumbers(weddinganalysis.monthlyinvestment, 0)}
                </h5>
                <h5>Total Time {weddinganalysis.afterhowmanyyears} Yrs</h5>
              </div>
              <div className="bubble">
                <h4>{holidayanalysis.name}</h4>
                <h5>
                  Future Value Rs.{" "}
                  {help.formatNumbers(holidayanalysis.futurevalue, 0)}
                </h5>
                <h5>
                  Monthly Savings Rs.{" "}
                  {help.formatNumbers(holidayanalysis.monthlyinvestment, 0)}
                </h5>
                <h5>Total Time {holidayanalysis.afterhowmanyyears} Yrs</h5>
              </div>
              <div className="bubble">
                <h4>{homeanalysis.name}</h4>
                <h5>
                  Future Value Rs.{" "}
                  {help.formatNumbers(homeanalysis.futurevalue, 0)}
                </h5>
                <h5>
                  Monthly Savings Rs.{" "}
                  {help.formatNumbers(homeanalysis.monthlyinvestment, 0)}
                </h5>
                <h5>Total Time {homeanalysis.afterhowmanyyears} Yrs</h5>
              </div>
              <div className="bubble">
                <h4>{emergencyanalysis.name}</h4>
                <h5>
                  Future Value Rs.{" "}
                  {help.formatNumbers(emergencyanalysis.futurevalue, 0)}
                </h5>
                <h5>
                  Monthly Savings Rs.{" "}
                  {help.formatNumbers(emergencyanalysis.monthlyinvestment, 0)}
                </h5>
                <h5>Total Time {emergencyanalysis.afterhowmanyyears} Yrs</h5>
              </div>
            </div>

            <div className="row">
              <table>
                <thead>
                  <tr>
                    <th>Analysis</th>
                    <th>Future Value</th>
                    <th>Monthly Investment</th>
                    <th>Inflation</th>
                    <th>Rate of Return</th>
                    <th>Years</th>
                    <th>Current Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{childeducation.name}</td>
                    <td>
                      Rs. {help.formatNumbers(childeducation.futurevalue)}
                    </td>
                    <td>
                      Rs. {help.formatNumbers(childeducation.monthlyinvestment)}
                    </td>
                    <td>{childeducation.inflation} %</td>
                    <td>{childeducation.rateofreturn} %</td>
                    <td>{childeducation.afterhowmanyyears} Years</td>
                    <td>
                      Rs. {help.formatNumbers(childeducation.currentvalue)}
                    </td>
                  </tr>
                  <tr>
                    <td>{retirementanalysis.name}</td>
                    <td>
                      Rs. {help.formatNumbers(retirementanalysis.futurevalue)}
                    </td>
                    <td>
                      Rs.{" "}
                      {help.formatNumbers(retirementanalysis.monthlyinvestment)}
                    </td>
                    <td>{retirementanalysis.inflation} %</td>
                    <td>{retirementanalysis.rateofreturn} %</td>
                    <td>{retirementanalysis.afterhowmanyyears} Years</td>
                    <td>
                      Rs. {help.formatNumbers(retirementanalysis.currentvalue)}
                    </td>
                  </tr>
                  <tr>
                    <td>{weddinganalysis.name}</td>
                    <td>
                      Rs. {help.formatNumbers(weddinganalysis.futurevalue)}
                    </td>
                    <td>
                      Rs.{" "}
                      {help.formatNumbers(weddinganalysis.monthlyinvestment)}
                    </td>
                    <td>{weddinganalysis.inflation} %</td>
                    <td>{weddinganalysis.rateofreturn} %</td>
                    <td>{weddinganalysis.afterhowmanyyears} Years</td>
                    <td>
                      Rs. {help.formatNumbers(weddinganalysis.currentvalue)}
                    </td>
                  </tr>
                  <tr>
                    <td>{holidayanalysis.name}</td>
                    <td>
                      Rs. {help.formatNumbers(holidayanalysis.futurevalue)}
                    </td>
                    <td>
                      Rs.{" "}
                      {help.formatNumbers(holidayanalysis.monthlyinvestment)}
                    </td>
                    <td>{holidayanalysis.inflation} %</td>
                    <td>{holidayanalysis.rateofreturn} %</td>
                    <td>{holidayanalysis.afterhowmanyyears} Years</td>
                    <td>
                      Rs. {help.formatNumbers(holidayanalysis.currentvalue)}
                    </td>
                  </tr>
                  <tr>
                    <td>{homeanalysis.name}</td>
                    <td>Rs. {help.formatNumbers(homeanalysis.futurevalue)}</td>
                    <td>
                      Rs. {help.formatNumbers(homeanalysis.monthlyinvestment)}
                    </td>
                    <td>{homeanalysis.inflation} %</td>
                    <td>{homeanalysis.rateofreturn} %</td>
                    <td>{homeanalysis.afterhowmanyyears} Years</td>
                    <td>Rs. {help.formatNumbers(homeanalysis.currentvalue)}</td>
                  </tr>
                  <tr>
                    <td>{emergencyanalysis.name}</td>
                    <td>
                      Rs. {help.formatNumbers(emergencyanalysis.futurevalue)}
                    </td>
                    <td>
                      Rs.{" "}
                      {help.formatNumbers(emergencyanalysis.monthlyinvestment)}
                    </td>
                    <td>{emergencyanalysis.inflation} %</td>
                    <td>{emergencyanalysis.rateofreturn} %</td>
                    <td>{emergencyanalysis.afterhowmanyyears} Years</td>
                    <td>
                      Rs. {help.formatNumbers(emergencyanalysis.currentvalue)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default FinancialPlanningTool;
