import React, { useEffect } from "react";
import Header from "../Helpers/Header";
import "./VolatilityGame2.css";
import firebase from "firebase";
import db, { auth } from "../Helpers/Firebase";

function VolatilityGame2() {
  const VGIcon =
    "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Ftemplatesicon%2Fvg2icon.png?alt=media&token=bf95f7fc-14e8-474e-aa82-9c73e00ef09e";

  useEffect(() => {
    const starttime = new Date().getTime();
    const starttimestamp = Math.floor(starttime / 1000);
    return () => {
      const endtime = new Date().getTime();
      const endtimestamp = Math.floor(endtime / 1000);
      const difference = endtimestamp - starttimestamp;
      db.collection("analytics")
        .doc(auth?.currentUser?.uid || localStorage.getItem("_userid"))
        .update({
          volatilitygame2: firebase.firestore.FieldValue.increment(difference),
        });
    };
  }, []);

  return (
    <div className="volatilitygame2">
      <Header />
      <div className="downloads">
        <div className="eachdownloads">
          <a
            href="https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fzips%2FVG2.0_win.zip?alt=media&token=b0017d3a-2473-4263-9cd8-94cef635600a"
            download
          >
            <img src={VGIcon} />
            <h3>Download for Windows</h3>
          </a>
        </div>
        <div className="eachdownloads">
          <a
            href="https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fzips%2FVG2.0_mac.zip?alt=media&token=58616b7e-fbba-4c23-9f47-c2f56116ee0a"
            download
          >
            <img src={VGIcon} />
            <h3>Download for Mac</h3>
          </a>
        </div>
        <div className="eachdownloads">
          <a href="https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fpptsdownload%2FVolatilityGame2.0forVC.pptx?alt=media&token=49854d4f-196c-489a-9465-eeefa17a3d18">
            <img src={VGIcon} />
            <h3>Volatility Game 2.0 PPT</h3>
          </a>
        </div>
        <div className="eachdownloads">
          <iframe
            src="https://www.youtube.com/embed/Ko4YN7CnWEc"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div className="eachdownloads">
          <video controls>
            <source
              src="https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fvideos%2Fvg2.0_intro.mp4?alt=media&token=a11abe3e-f616-4cb1-b120-0c660546d34b"
              type="video/mp4"
            />
            <source
              src="https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fvideos%2Fvg2.0_intro.mp4?alt=media&token=a11abe3e-f616-4cb1-b120-0c660546d34b"
              type="video/ogg"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="eachdownloads imagedownload">
          <a
            href={`https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fimages%2FVGFlier.jpeg?alt=media&token=fcf3ebac-88ed-47e1-87fd-08d2275d07c4`}
            download
          >
            <img src="https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fimages%2FVGFlier.jpeg?alt=media&token=fcf3ebac-88ed-47e1-87fd-08d2275d07c4" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default VolatilityGame2;
