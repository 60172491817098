class Help_methods {
  formatNumbers(value, precision = 2) {
    let val = value || 0;
    return Number(val)
      ?.toFixed(precision)
      ?.toString()
      ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  countDecimals = function (value) {
    if (value % 1 != 0) return value.toString().split(".")[1].length;
    return 0;
  };
}

const help = new Help_methods();
export default help;
