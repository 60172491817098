import React from "react";
import BarChart from "react-easy-bar-chart";
import "./ShowGraph.css";

function ShowGraph({
  minified,
  mobilepdf,
  childeducation,
  retirementanalysis,
  weddinganalysis,
  holidayanalysis,
  homeanalysis,
  emergencyanalysis,
}) {
  let data = [
    {
      title: `${childeducation.name} Current`,
      value: Number(childeducation.currentvalue),
      color: "#121e1e",
    },
    {
      title: childeducation.name + " Future",
      value: Number(childeducation.futurevalue),
      color: "#f2b42b",
    },
    {
      title: retirementanalysis.name + " Current",
      value: Number(retirementanalysis.currentvalue),
      color: "#121e1e",
    },
    {
      title: retirementanalysis.name + " Future",
      value: Number(retirementanalysis.futurevalue),
      color: "#f2b42b",
    },
    {
      title: weddinganalysis.name + " Current",
      value: Number(weddinganalysis.currentvalue),
      color: "#121e1e",
    },
    {
      title: weddinganalysis.name + " Future",
      value: Number(weddinganalysis.futurevalue),
      color: "#f2b42b",
    },
    {
      title: holidayanalysis.name + " Current",
      value: Number(holidayanalysis.currentvalue),
      color: "#121e1e",
    },
    {
      title: holidayanalysis.name + " Future",
      value: Number(holidayanalysis.futurevalue),
      color: "#f2b42b",
    },
    {
      title: homeanalysis.name + " Current",
      value: Number(homeanalysis.currentvalue),
      color: "#121e1e",
    },
    {
      title: homeanalysis.name + " Future",
      value: Number(homeanalysis.futurevalue),
      color: "#f2b42b",
    },
    {
      title: emergencyanalysis.name + " Current",
      value: Number(emergencyanalysis.currentvalue),
      color: "#121e1e",
    },
    {
      title: emergencyanalysis.name + " Future",
      value: Number(emergencyanalysis.futurevalue),
      color: "#f2b42b",
    },
  ];
  return (
    <div
      className="showgraph"
      style={{
        width: mobilepdf ? 1500 : minified ? "50%" : "100%",
      }}
    >
      <BarChart
        xAxis="Category"
        yAxis="Current vs Future Value"
        height={mobilepdf ? 300 : minified ? 200 : 400}
        width={mobilepdf ? 1250 : minified ? 1250 : window.innerWidth}
        data={data}
      />
    </div>
  );
}
export default ShowGraph;
