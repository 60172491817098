import React, { useState, useEffect, useRef } from "react";
import "./FinancialplanningToolbackend.css";
import db, { auth } from "../Helpers/Firebase";
import Header from "../Helpers/Header";
import Modal from "react-modal";
import FinacialPlanningQA from "./FinancialPlanningQA";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "4px solid #143943",
    maxHeight: "80vh",
    maxWidth: "100vw",
    width: "80vw",
    backgroundColor: "#fae1b1",
  },
};

function FinancialplanningToolbackend() {
  const [allData, setAllData] = useState([]);
  const [selectedData, setSelectedData] = useState(null);

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    //   subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        let userid = user.uid;
        db.collection("financialplanning")
          .where("vcid", "==", userid)
          .get()
          .then((alldata) => {
            let tmp = [];
            alldata.docs.map((doc) => {
              tmp.push(doc.data());
            });
            tmp.sort(function (a, b) {
              // Turn your strings into dates, and then subtract them
              // to get a value that is either negative, positive, or zero.
              return new Date(b.date) - new Date(a.date);
            });
            setAllData(tmp);
          });
      }
    });
  }, []);
  return (
    <div className="financialplanningtoolbackend">
      <Header />
      <div className="tabledata">
        {selectedData && (
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div
              className="allcontent"
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <h5>Date: {selectedData?.date?.split("GMT")?.[0]}</h5>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h5 style={{ paddingRight: 20 }}>{selectedData?.name}</h5>
                <h5 style={{ paddingRight: 20 }}>{selectedData?.email}</h5>
                <h5>{selectedData?.mobile}</h5>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h5 style={{ paddingRight: 20 }}>
                  Monthly Expense: Rs {selectedData?.monthlyexpense}
                </h5>
                <h5 style={{ paddingRight: 20 }}>DOB: {selectedData?.dob}</h5>
              </div>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <h5 style={{ paddingRight: 20 }}>Risk Analysis Answers:</h5>
                <h5 style={{ paddingRight: 20 }}>
                  Q1 {FinacialPlanningQA.question1}
                  <br></br> <br></br>
                  A1{" "}
                  {selectedData.riskanalysis1 === "a"
                    ? FinacialPlanningQA.optionQ1A
                    : selectedData.riskanalysis1 === "b"
                    ? FinacialPlanningQA.optionQ1B
                    : selectedData.riskanalysis1 === "c"
                    ? FinacialPlanningQA.optionQ1C
                    : FinacialPlanningQA.optionQ1D}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Q2 {FinacialPlanningQA.question2}
                  <br></br> <br></br>
                  A2{" "}
                  {selectedData.riskanalysis2 === "a"
                    ? FinacialPlanningQA.optionQ2A
                    : selectedData.riskanalysis2 === "b"
                    ? FinacialPlanningQA.optionQ2B
                    : selectedData.riskanalysis2 === "c"
                    ? FinacialPlanningQA.optionQ2C
                    : FinacialPlanningQA.optionQ2D}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Q3 {FinacialPlanningQA.question3}
                  <br></br> <br></br>
                  A3{" "}
                  {selectedData.riskanalysis3 === "a"
                    ? FinacialPlanningQA.optionQ3A
                    : selectedData.riskanalysis3 === "b"
                    ? FinacialPlanningQA.optionQ3B
                    : selectedData.riskanalysis3 === "c"
                    ? FinacialPlanningQA.optionQ3C
                    : FinacialPlanningQA.optionQ3D}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Q4 {FinacialPlanningQA.question4}
                  <br></br> <br></br>
                  A4{" "}
                  {selectedData.riskanalysis4 === "a"
                    ? FinacialPlanningQA.optionQ4A
                    : selectedData.riskanalysis4 === "b"
                    ? FinacialPlanningQA.optionQ4B
                    : selectedData.riskanalysis4 === "c"
                    ? FinacialPlanningQA.optionQ4C
                    : FinacialPlanningQA.optionQ4D}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Q5 {FinacialPlanningQA.question5}
                  <br></br> <br></br>
                  A5{" "}
                  {selectedData.riskanalysis5 === "a"
                    ? FinacialPlanningQA.optionQ5A
                    : selectedData.riskanalysis5 === "b"
                    ? FinacialPlanningQA.optionQ5B
                    : selectedData.riskanalysis5 === "c"
                    ? FinacialPlanningQA.optionQ5C
                    : FinacialPlanningQA.optionQ5D}
                </h5>
              </div>

              <h5 style={{ marginBottom: 0 }}>
                {selectedData?.childeducation?.name}
              </h5>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h5 style={{ paddingRight: 20 }}>
                  Current Value: Rs {selectedData?.childeducation?.currentvalue}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Years: {selectedData?.childeducation?.afterhowmanyyears}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Future Value: Rs {selectedData?.childeducation?.futurevalue}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Inflation: {selectedData?.childeducation?.inflation}%
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Monthly Investment: Rs{" "}
                  {selectedData?.childeducation?.monthlyinvestment}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Rate of Return: {selectedData?.childeducation?.rateofreturn}%
                </h5>
              </div>

              <h5 style={{ marginBottom: 0 }}>
                {selectedData?.emergencyanalysis?.name}
              </h5>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h5 style={{ paddingRight: 20 }}>
                  Current Value: Rs{" "}
                  {selectedData?.emergencyanalysis?.currentvalue}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Years: {selectedData?.emergencyanalysis?.afterhowmanyyears}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Future Value: Rs{" "}
                  {selectedData?.emergencyanalysis?.futurevalue}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Inflation: {selectedData?.emergencyanalysis?.inflation}%
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Monthly Investment: Rs{" "}
                  {selectedData?.emergencyanalysis?.monthlyinvestment}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Rate of Return:{" "}
                  {selectedData?.emergencyanalysis?.rateofreturn}%
                </h5>
              </div>

              <h5 style={{ marginBottom: 0 }}>
                {selectedData?.holidayanalysis?.name}
              </h5>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h5 style={{ paddingRight: 20 }}>
                  Current Value: Rs{" "}
                  {selectedData?.holidayanalysis?.currentvalue}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Years: {selectedData?.holidayanalysis?.afterhowmanyyears}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Future Value: Rs {selectedData?.holidayanalysis?.futurevalue}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Inflation: {selectedData?.holidayanalysis?.inflation}%
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Monthly Investment: Rs{" "}
                  {selectedData?.holidayanalysis?.monthlyinvestment}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Rate of Return: {selectedData?.holidayanalysis?.rateofreturn}%
                </h5>
              </div>

              <h5 style={{ marginBottom: 0 }}>
                {selectedData?.homeanalysis?.name}
              </h5>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h5 style={{ paddingRight: 20 }}>
                  Current Value: Rs {selectedData?.homeanalysis?.currentvalue}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Years: {selectedData?.homeanalysis?.afterhowmanyyears}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Future Value: Rs {selectedData?.homeanalysis?.futurevalue}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Inflation: {selectedData?.homeanalysis?.inflation}%
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Monthly Investment: Rs{" "}
                  {selectedData?.homeanalysis?.monthlyinvestment}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Rate of Return: {selectedData?.homeanalysis?.rateofreturn}%
                </h5>
              </div>

              <h5 style={{ marginBottom: 0 }}>
                {selectedData?.retirementanalysis?.name}
              </h5>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h5 style={{ paddingRight: 20 }}>
                  Current Value: Rs{" "}
                  {selectedData?.retirementanalysis?.currentvalue}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Years: {selectedData?.retirementanalysis?.afterhowmanyyears}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Future Value: Rs{" "}
                  {selectedData?.retirementanalysis?.futurevalue}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Inflation: {selectedData?.retirementanalysis?.inflation}%
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Monthly Investment: Rs{" "}
                  {selectedData?.retirementanalysis?.monthlyinvestment}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Rate of Return:{" "}
                  {selectedData?.retirementanalysis?.rateofreturn}%
                </h5>
              </div>

              <h5 style={{ marginBottom: 0 }}>
                {selectedData?.weddinganalysis?.name}
              </h5>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h5 style={{ paddingRight: 20 }}>
                  Current Value: Rs{" "}
                  {selectedData?.weddinganalysis?.currentvalue}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Years: {selectedData?.weddinganalysis?.afterhowmanyyears}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Future Value: Rs {selectedData?.weddinganalysis?.futurevalue}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Inflation: {selectedData?.weddinganalysis?.inflation}%
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Monthly Investment: Rs{" "}
                  {selectedData?.weddinganalysis?.monthlyinvestment}
                </h5>
                <h5 style={{ paddingRight: 20 }}>
                  Rate of Return: {selectedData?.weddinganalysis?.rateofreturn}%
                </h5>
              </div>
            </div>
          </Modal>
        )}
        {allData.map((each) => {
          return (
            <div className="eachrecord">
              <h4>{each.name}</h4>
              <h4>{each.email}</h4>
              <h4>{each.mobile}</h4>
              <h4>{each.date.split("GMT")[0]}</h4>
              <h4>{each.dob}</h4>
              <button
                onClick={() => {
                  setSelectedData(each);
                  openModal();
                }}
              >
                View More
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FinancialplanningToolbackend;
