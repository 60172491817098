import React, { useEffect } from "react";
import Header from "../Helpers/Header";
import "./Riddles.css";
import firebase from "firebase";
import db, { auth } from "../Helpers/Firebase";

function Riddles() {
  useEffect(() => {
    const starttime = new Date().getTime();
    const starttimestamp = Math.floor(starttime / 1000);
    return () => {
      const endtime = new Date().getTime();
      const endtimestamp = Math.floor(endtime / 1000);
      const difference = endtimestamp - starttimestamp;
      db.collection("analytics")
        .doc(auth?.currentUser?.uid || localStorage.getItem("_userid"))
        .update({
          riddles: firebase.firestore.FieldValue.increment(difference),
        });
    };
  }, []);
  const Ppt =
    "https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fimages%2Fppt.svg?alt=media&token=d2919f26-a122-4456-bbd0-68c09f469c1a";

  return (
    <div className="riddles">
      <Header />
      <div className="downloads">
        <div className="eachdownloads">
          <img src={Ppt} width={60} />
          <h3>Out of the box</h3>
          <a
            href="https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fpptsdownload%2FOut%20Of%20Box.pptx?alt=media&token=3fddaf9b-136a-40b9-b99a-ea50df92dd2a"
            download
          >
            Download
          </a>
        </div>
        <div className="eachdownloads">
          <img src={Ppt} width={60} />
          <h3>How many boxes</h3>
          <a
            href="https://firebasestorage.googleapis.com/v0/b/suskanmaster.appspot.com/o/assets%2Fpptsdownload%2FHow%20Many%20boxes.pptx?alt=media&token=9aec075e-e60c-443d-817c-2092f21ba0a9"
            download
          >
            Download
          </a>
        </div>
      </div>
    </div>
  );
}

export default Riddles;
