const FinacialPlanningQA = {
  question1:
    "Have you invested any money in the past? If yes, what has been your experience so far?",
  optionQ1A:
    "a. I have just started investing and do not have any prior investment experience.",
  optionQ1B: "b. I invest mainly in fixed deposits, post office savings.",
  optionQ1C:
    "c. Option b plus debt-based mutual funds, monthly income plans and traditional insurance plans.",
  optionQ1D: "d. Option b & c plus equity-based mutual funds and ULIPs.",
  optionQ1E:
    " e. Option b, c & d plus a mix of equity shares,real estate and derivatives.",

  question2:
    "What percentage of your monthly income is generally available for investment?",
  optionQ2A: "a. Less than 10%",
  optionQ2B: "b. 11% - 20%",
  optionQ2C: "c. 21% - 30%",
  optionQ2D: "d. 31% - 40%",
  optionQ2E: "e. Greater than 41%",

  question3: "Which age group do you belong to?",
  optionQ3A: "a. Over 61 yrs",
  optionQ3B: "b. 51 to 60 yrs",
  optionQ3C: "c. 41 to 50 yrs",
  optionQ3D: "d. 31 to 40 yrs",
  optionQ3E: "e. 18 to 30 yrs",

  question4:
    "If you have bought an equity share and its value fell by more than 25%, without any change in the fundamentals of the company. What will you do?",
  optionQ4A: "a. Sell the entire holding.",
  optionQ4B: "b. Sell part of the holding to prevent large losses.",
  optionQ4C:
    "c. Maintain current holding and wait and watch further before deciding anything.",
  optionQ4D: "d. Increase holding as fundamentals are still good.",
  optionQ4E: "e. Buy very aggressively, to lower average buying cost.",

  question5: "Which statement best describes your approach as an investor?",
  optionQ5A:
    "a. I want my investment to be completely safe and I don’t mind accepting very low returns for it.",
  optionQ5B:
    "b. I want to preserve my capital in the long term, but I don’t mind taking small risks for little extra returns.",
  optionQ5C:
    "c. I want investment growth in the long run; I don’t mind losing part of my principal in the short term.",
  optionQ5D:
    "d. I want very high returns, and I am willing to take some amount of risk.",
  optionQ5E:
    "e. I want my investments to grow substantially and it should earn the highest possible returns.",
};

export default FinacialPlanningQA;
